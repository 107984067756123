<!-- 乡村治理 -->
<template>
	<div>
		<div class="page_temp page_temp_left">
			<div style="position: relative;">
				<item-title name="村组管理" english="Village management" />
				<lineBorderBox type='bottom' />
				<div class="contentBox villager ">
					<z-label name="村两委" />
					<div class="cls-pane-clw committee scrollbar">
						<div v-for="(item, index) in v_cun_committee" class="committee-item" :key="index">
							<div class="img" style="width: 0.56rem;height: 0.56rem;position: relative;display: flex;justify-content: center;align-items: center;">
								<lineBorderBox />
								<img style="width: 80%;height: 80%;" :src="item.image || clw_noImg" alt="">
							</div>
							<ul>
								<li>
									<z-label :name="item.name" />
								</li>
								<li><span>职务</span>:&nbsp;{{ item.jobName }}</li>
								<li><span>联系方式</span>:&nbsp;{{ item.phone }}</li>
							</ul>
						</div>
					</div>
					<z-label name="村小组" />
					<div class="cls-pane-clw cz">
						<div style="box-sizing: border-box;width: 100%;">
							<div class="cz_list" style="background-color: rgba(6,65,153,0.6);" @click="toggleZu">
								<span>村小组</span>
								<span>户数</span>
								<span>负责人</span>
								<span>联系方式</span>
							</div>
						</div>
						<div class="cz_list_box scrollbar">
							<div v-for="(item, index) in v_zu_list" class="cz_list"
								:class="cz_list_active == index ? 'cz_list_active' : ''" :key="index"
								@click="onMoveMapToZuPolygon(item, index)">
								<span>{{ item.mc }}</span>
								<span>{{ item.hs }}</span>
								<span>{{ item.zrr }}</span>
								<span>{{ item.lxfs }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 0.2rem;position: relative;padding-bottom: 0.2rem;">
				<item-title name="人口管理" english="population management" />
				<lineBorderBox type='bottom' />
				<div class="contentBox population">
					<div class="search">
						<input type="text" class=" searchInput" v-model="searchText" placeholder="请输入姓名或身份证号">
						<i class="el-icon-close" v-if="searchText != ''"
							@click="searchText = '', landSearchData = []"></i>
						<div class="search-btn" @click="searchLand">搜索</div>
						<div class="searchListBox" v-if="searchText != '' && landSearchData.length > 0">
							<ul class="searchList">
								<li v-for="(item, index) in landSearchData" :key="index" @click="flyToPeople(item)">
									<span>{{ item.name }}</span>
									<span style="margin-right: .1rem;">{{ item.address }}</span>
									<span>{{ item.birthday }}</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="dataList-box">
						<div class="dataList-item" v-for="(item, index) in population" :key="index">
							<img :src="item.icon" alt="">
							<div class="item-info">
								<span class="type">{{ item.title }}</span>
								<div>
									<span>{{ item.num }}</span>
									<span v-show="item.unit">（{{item.unit}}）</span>
								</div>
							</div>
						</div>
					</div>
					<div class="pie_box">
						<z-label name="重点人群分布" />
						<div class="pie_box_content">
							<div class="pie_echarts_box">
								<div class="center"></div>
								<div id="pie_echarts" class="pie_echarts"></div>
							</div>
							<ul class="pie_legend">
								<li><i></i>20岁以下<span>{{emphasis_echarts[0]}}%</span></li>
								<li><i></i>21-40岁<span>{{emphasis_echarts[1]}}%</span></li>
								<li><i></i>41-60岁<span>{{emphasis_echarts[2]}}%</span></li>
								<li><i></i>61-80岁<span>{{emphasis_echarts[3]}}%</span></li>
								<li><i></i>81岁以上<span>{{emphasis_echarts[4]}}%</span></li>
							</ul>
						</div>
						
					</div>
				</div>
			</div>


			<message-pop class="message-pop" :option="xcdtOption" @clearPop="closePane" style="top: 5.4rem;"
				v-if="messageActive == 2">
				<div class="pop-content">
					<span class="title">
						{{ villageData.villageNewsTitle }}
					</span>
					<div class="v-html" v-html="villageData.villageNewsContent"></div>
				</div>
			</message-pop>
		</div>
		<div class="page_temp page_temp_right">
			<div class="contentBox" style="padding-bottom: 0.2rem;">
				<lineBorderBox type='bottom' />
				<item-title name="特殊人群" english="special population" />
				<div class="contentBox population">
					<z-label name="关爱人群" />
					<div class="histogram">
						<span>单位（人）</span>
						<div id="industryCharts" style="width: 3.2rem;height: 2rem;"></div>
					</div>
					<z-label name="重点人群" />
					<div class="focusGroups">
						<div v-for="(item, index) in zdrq_data" :key="index" @click="requestZdrqByType(index, item)">
							<div class="item">
								<svg xmlns="http://www.w3.org/2000/svg" :style="{color:item.color}" width="40" height="40" fill="currentColor" class="bi bi-person-bounding-box" viewBox="0 0 16 16">
								<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z"/>
								<path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
								</svg>
							</div>
							<span style="color: rgba(255,255,255,0.6);font-size: 0.12rem;margin-top: 0.08rem;padding-left: 0.1rem;">{{item.type}}:</span>
							<div style="display: flex;align-items: flex-end;padding-left: 0.1rem;">
								<span style="font-size: 0.24rem;margin-right: 0.04rem;line-height: 0.24rem;">{{item.num}}</span>
								<p style="font-size: 0.12rem;">名</p>
							</div>
						</div>	
					</div>
				</div>
			</div>
			<div style="margin-top: 0.2rem;position: relative;padding-bottom: 0.2rem;">
				<item-title :name="'乡村安防'" />
				<lineBorderBox type='bottom' />
				<div style="padding: 0.08rem">
					<div class="anfang"  @click="onCheckType2(0)" style="cursor: pointer; margin-top: 0.08rem;border: 0.02rem solid rgba(51,255,238,0.16);height: 1.2rem;display: flex;justify-content: space-between;align-items: center;">
						<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
							<div style="width: 0.96rem;height: 0.96rem;">
								<img style="width: 100%;" src="../assets/new_img/f2.png" alt="">
							</div>
							<p style="p{
								margin-left: 0.24rem;
								font-size: 0.12rem;
								line-height: 0.12rem;
								width: 0.72rem;
								height: 0.06rem;
								display: flex;
								align-items: flex-end;
								justify-content: center;
								color: rgba(225,225,225,.8);
								margin: 0.08rem 0;
								background: linear-gradient(180deg, rgba(9,98,229,0) 0%, #18F5F5 100%);
							}">安防摄像头</p>
						</div>
						<div style="display: flex;flex-direction: column;">
							<span style="font-size: 0.12rem;color: rgba(225,225,225,.4);">设备数量（个）</span>
							<span style="font-size: 0.2rem;color: #fff;">{{ v_video_list.length }}</span>
						</div>
						<div style="display: flex;flex-direction: column;">
							<span style="font-size: 0.12rem;color: rgba(225,225,225,.4);">覆盖范围（平方公里）</span>
							<span style="font-size: 0.2rem;color: #fff;">{{ v_video_list.length*0.4 }}</span>
						</div>
					</div>
				</div>
				<div style="padding: 0.08rem">
					<div class="anfang"  @click="onCheckType2(1)" style="cursor: pointer; border: 0.02rem solid rgba(51,255,238,0.16);height: 1.2rem;display: flex;justify-content: space-between;align-items: center;">
						<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
							<div style="width: 0.96rem;height: 0.96rem;">
								<img style="width: 100%;" src="../assets/new_img/f1.png" alt="">
							</div>
							<p style="p{
								margin-left: 0.24rem;
								font-size: 0.12rem;
								line-height: 0.12rem;
								width: 0.72rem;
								height: 0.06rem;
								display: flex;
								align-items: flex-end;
								justify-content: center;
								color: rgba(225,225,225,.8);
								margin: 0.08rem 0;
								background: linear-gradient(180deg, rgba(9,98,229,0) 0%, #18F5F5 100%);
							}">广播</p>
						</div>
						<div style="display: flex;flex-direction: column;">
							<span style="font-size: 0.12rem;color: rgba(225,225,225,.4);">设备数量（个）</span>
							<span style="font-size: 0.2rem;color: #fff;">{{ v_radio_list.length }}</span>
						</div>
						<div style="display: flex;flex-direction: column;">
							<span style="font-size: 0.12rem;color: rgba(225,225,225,.4);">覆盖范围（平方公里）</span>
							<span style="font-size: 0.2rem;color: #fff;">{{ v_radio_list.length/10 }}</span>
						</div>
					</div>
				</div>
				<div style="padding: 0.08rem">
					<div class="anfang"  @click="onCheckType2(2)" style="cursor: pointer; border: 0.02rem solid rgba(51,255,238,0.16);height: 1.2rem;display: flex;justify-content: space-between;align-items: center;">
						<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
							<div style="width: 0.96rem;height: 0.96rem;">
								<img style="width: 100%;" src="../assets/new_img/f3.png" alt="">
							</div>
							<p style="p{
								margin-left: 0.24rem;
								font-size: 0.12rem;
								line-height: 0.12rem;
								width: 0.72rem;
								height: 0.06rem;
								display: flex;
								align-items: flex-end;
								justify-content: center;
								color: rgba(225,225,225,.8);
								margin: 0.08rem 0;
								background: linear-gradient(180deg, rgba(9,98,229,0) 0%, #18F5F5 100%);
							}">手环</p>
						</div>
						<div style="display: flex;flex-direction: column;">
							<span style="font-size: 0.12rem;color: rgba(225,225,225,.4);">设备数量（个）</span>
							<span style="font-size: 0.2rem;color: #fff;">{{ v_handring_list.length }}</span>
						</div>
						<div style="display: flex;flex-direction: column;">
							<span style="font-size: 0.12rem;color: rgba(225,225,225,.4);">覆盖范围（平方公里）</span>
							<span style="font-size: 0.2rem;color: #fff;">{{ v_handring_list.length/5 }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="btn-box">
            <!-- 矿山安防 -->
            <a href="#" @click="requestVideoData()"  class="btn-img" v-if="isShow"></a>
        </div>
		
		<div id="id_video" v-show="false">
			<!-- <div id="id_video_play"></div>
			<div class="cls-tip-close" @click="onCloseVideo">X</div> -->
		</div>

		<dot-pop :name="'视频'" v-show="v_if_show_video && dotPopActive != -1" :width="800" :height="500" type="video" :x="v_video.x" :y="v_video.y" :z="1" @closePane="onCloseVideo">
			<div id="id_video_play" style="width:100%;height:100%;display:none"></div>
			<easy-player id="player" live="true" show-custom-button="true" isH265="true" style="width:100%;height:100%;"></easy-player>
		</dot-pop>	

		<dot-pop :name="'健康手环'" type="pane" v-show="v_if_show_handring" class="cls-pop-pane-sh" :x="v_handring.x" :y="v_handring.y" :z="1" @closePane="onCloseHandring">
			<div class="row">
				<div class="flex_c">
					<img src="../assets/img/farming/1.png" alt="" height="26" width="26">
					<div>
						<p>姓名：</p>
						<p>{{ v_handring_msg.info.name || '' }}</p>
					</div>
				</div>
				<div class="flex_c">
					<img src="../assets/img/farming/4.png" alt="" height="26" width="26">
					<div>
						<p>出生日期：</p>
						<p>{{ v_handring_msg.info.ownerBirthday || '' }}</p>
					</div>
				</div>
				<div class="flex_c">
					<img src="../assets/img/govern/1.png" alt="" height="26" width="26">
					<div>
						<p>性别：</p>
						<p>{{ v_handring_msg.info.ownerGender=='F'?'女':'男' }}</p>
					</div>
				</div>
				<div class="flex_c">
					<img src="../assets/img/govern/2.png" alt="" height="26" width="26">
					<div>
						<p>身高：</p>
						<p>{{ v_handring_msg.info.height  || '' }}</p>
					</div>
				</div>
				<div class="flex_c">
					<img src="../assets/img/govern/3.png" alt="" height="26" width="26">
					<div>
						<p>体重：</p>
						<p>{{ v_handring_msg.info.weight  || '' }}</p>
					</div>
				</div>
				<div class="flex_c">
					<img src="../assets/img/farming/5.png" alt="" height="26" width="26">
					<div>
						<p>联系电话：</p>
						<p>{{ v_handring_msg.info.deviceMobileNo  || '' }}</p>
					</div>
				</div>

				<div class="row-2">
					<span :class="tableCur == 0 ? 'cur_pop_list' : ''" @click="tableCur = 0">监控数据</span>
					<span :class="tableCur == 1 ? 'cur_pop_list' : ''" @click="tableCur = 1">设备数据</span>
					<span class="cur_pop_list_track" @click="toggleHandRingPath(v_handring.id)">查看轨迹</span>
				</div>
				<div v-if="tableCur == 0 " style="width:100%;">
					<div class="row-4 ">
						<div class="list flex_c">体温：<span>{{ v_handring_msg.data.temperature  || '36.4' }}</span></div>
						<div class="list flex_c">计步：<span>{{ v_handring_msg.data.pedometer  || '2100' }}</span></div>
						<div class="list flex_c">收缩压：<span>{{ v_handring_msg.data.sdp  || '117' }}</span></div>
						<div class="list flex_c">舒张压：<span>{{ v_handring_msg.data.dbp  || '78' }}</span></div>
						<div class="list flex_c">心率：<span>{{ v_handring_msg.data.heartRate  || '71' }}</span></div>
						<div class="list flex_c">血氧：<span>{{ v_handring_msg.data.oxygen  || '99' }}</span></div>
					</div>
					<p class="info"><img src="../assets/img/govern/bell.png" alt="" height="13" width="13">报警信息：</p>
					<div class="row-3">
						<span>时间</span>
						<span>内容</span>
					</div>
					<div style="height: 0.55rem;overflow: auto;" class="scrollbar" v-if=" v_handring_msg.warnings.length>0">
						<div class="row-5" v-for="(item, index) in v_handring_msg.warnings" :key="index">
							<span>{{ item.createTime }}</span>
							<span class="f-yollow">{{ item.content }}</span>
						</div>
					</div>
					<div class="no-data" v-else>暂无数据</div>
				</div>
				<div v-if="tableCur == 1" style="width:100%;">
					<div class="row-4 ">
						<div class="list flex_c" >设备串号：<span>{{v_handring_msg.info.imei}}</span></div>
						<div class="list flex_c" >设备状态：<span>{{v_handring_msg.info.status == 'warning' ? '告警' : v_handring_msg.info.status == 'online' ? '在线' : '离线'}}</span></div>
						<div class="list flex_c" >限制呼入：<span>{{v_handring_msg.info.restricted == 'N' ? '不限制呼入' : '启用限制呼入'}}</span></div>
						<div class="list flex_c" >跌倒报警：<span>{{v_handring_msg.info.fallingAlarm == 'N' ? '开启跌倒报警' : '关闭跌倒报警'}}</span></div>
						<div class="list flex_c" >血压预警阈值（高）：<span>{{v_handring_msg.info.bpThresholdHigh}}</span></div>
						<div class="list flex_c" >血压预警阈值（低）：<span>{{v_handring_msg.info.bpThresholdLow}}</span></div>
						<div class="list flex_c" >心率预警（高）：<span>{{v_handring_msg.info.hrmThresholdHigh}}</span></div>
						<div class="list flex_c" >心率预警（低）：<span>{{v_handring_msg.info.hrmThresholdLow}}</span></div>
					</div>
				</div>
			</div>
		</dot-pop>

		<div id="id-hu-info" v-show="v_hu_pane_show">
			<div class="cls-hu-title">住户信息<div class="cls-close-hu" @click="closeHuPane">X</div>
			</div>
			<div>户主姓名：{{ v_hu_info.xm }}</div>
			<div>户主地址：{{ v_hu_info.dz }}</div>
			<div>联系方式：{{ v_hu_info.lxdh }}</div>
			<div>建筑面积：{{ v_hu_info.jzmj }}<span v-show="v_hu_info.jzmj != '' && v_hu_info.jzmj != '-'">平方米</span>
			</div>
			<div class="">
				<span :class="{ 'cls-hu-button': v_hu_pane_index == 0 }"
					@click="v_hu_pane_index = 0">家庭成员</span>&nbsp;&nbsp;
				<span :class="{ 'cls-hu-button': v_hu_pane_index == 1 }" @click="v_hu_pane_index = 1">地块信息</span>
			</div>
			<div class="cls-hu-pane" v-show="v_hu_pane_index == 0">
				<div><span>序号</span><span>姓名</span><span>性别</span><span>出生日期</span><span>与户主关系</span></div>
				<div v-for="(item, index) in v_hu_info.rkList" :key="index">
					<span>{{ index + 1 }}</span>
					<span>{{ item.xm }}</span>
					<span>{{ item.xb }}</span>
					<span>{{ item.csrq }}</span>
					<span>{{ item.yhzgx }}</span>
				</div>
			</div>
			<div class="cls-hu-pane" v-show="v_hu_pane_index == 1">
				<div><span>序号</span><span>地块名称</span><span>类型</span><span>面积</span><span>操作</span></div>
				<div v-for="(item, index2) in v_hu_info.dkList" :key="index2">
					<span>{{ index2 + 1 }}</span>
					<span>{{ item.mc }}</span>
					<span>{{ item.lx }}</span>
					<span>{{ item.mj }}亩</span>
					<span @click="onFlyToDkPolygon(index2)">定位</span>
				</div>
			</div>
		</div>
		
		<dot-pop :name="'住户信息'" v-if="(rk_data.geometry && messageActive == 3)" class="cls-pop-pane"
			:x="rk_data.geometry.center.x" :y="rk_data.geometry.center.y" :z="$parent.getZ()" @closePane="closeHuPane">
			<div style="display: flex;flex-direction: column;height: 100%;overflow: hidden;">
				<el-row style="height: 40px;line-height: 40px;border-bottom: 1px solid rgba(225, 225, 225, 0.6);">
					<el-col :span="16">住址:&nbsp;{{ rk_data.address }}</el-col>
					<el-col :span="8">住户:&nbsp;{{ rk_data.member.length + '人' }}</el-col>
				</el-row>
				<el-row style="flex: 1;overflow: auto;height: 100%;" class="scrollbar">
					<el-col v-for="(item, index) in rk_data.member" :key="index"
						style="line-height: 30px;border-bottom: 1px dashed  rgba(225, 225, 225, 0.6);">
						<el-row>姓名:&nbsp;{{ item.name + '(' + item.relationship + ')' }}</el-row>
						<el-row>性别:&nbsp;{{ item.gender }}</el-row>
						<el-row>出生:&nbsp;{{
                            item.birthday.split('-')[0] + '年' + item.birthday.split('-')[1] + '月' +
                                item.birthday.split('-')[2] + '日'
                        }}</el-row>
					</el-col>
				</el-row>
			</div>
		</dot-pop>
		
		<dot-pop :name="item.name || item.mc" v-for="(item, index) in dotData" :key="index" v-show="dotPopActive != -1"
			:x="item.shape ? item.shape[0] : item.geometry.pointArr[0]"
			:y="item.shape ? item.shape[1] : item.geometry.pointArr[1]" :z="7" :type='`dot`' :color="dotColor">
			<img style="width: 0.3rem;" :src="dotIcon" @click="openPop(item)">
		</dot-pop>
		
		<dot-pop :name="'贫困户信息'" type="pane" v-show="v_if_show_pkh" :x="v_pkh_info.shape[0]" :y="v_pkh_info.shape[1]" :z="1" @closePane="onClosePkh">
			<div style="height:150px;width:200px;font-size:14px;line-height:30px;">
				<div class="row-2 flex_c">
					<p>户主姓名：</p>
					<p>{{ v_pkh_info.name || '' }}</p>
				</div>
				<div class="row-2 flex_c">
					<p>致贫原因：</p>
					<p>{{ v_pkh_info.zpyy || '' }}</p>
				</div>
				<div class="row-2 flex_c">
					<p>帮扶对策：</p>
					<p>{{ v_pkh_info.bfdc || '' }}</p>
				</div>
				<div class="row-2 flex_c">
					<p>帮扶人员：</p>
					<p>{{ v_pkh_info.bfry || '' }}</p>
				</div>
				<div class="row-2 flex_c">
					<p>所属村名：</p>
					<p>{{ v_pkh_info.sscmc || '' }}</p>
				</div>
			</div>
		</dot-pop>

		<map-button-bar :enableClickMapJmd="true" :showBtnHu="true" ref="mapButtonBar" @showPop='messageActive = -1'></map-button-bar>
	</div>

</template>

<script>
	import {
		getDiShiZhouCodeByCunCode,
		getTwoBitDotNumber,
		getEnvelope,
		getEnvelopeByPoints
	} from '../util/common.js';
	import lineBorderBox from '../components/line-border-box.vue'
	import {
		getLonLatArray,
		addPoint,
		addPolygonFunction,
		addLine,
		cleanMarkerListByType,
		getMakerById
	} from '../util/drawShape.js';
	import {
		getSearchPeople,
		getStaff,
		getCloudSpace,
		getHousehold,
		getHouseholdDetail,
		getvillageInfo,
		getFocusPeople,
		getPeople,
		getFocusPeopleList,
		getRulesList,
		getSafe,
		getVillageArea,
		getVillageNews,
		getSpecialPeopleList,
		watchList,
		watchDetail
	} from '@/api/govern.js'
	import lineBox from '../components/line-border-box.vue'
	import itemTitle from '../components/item-title.vue'
	import http from '../util/http.js';
	import config from '../config.js';
	import * as echarts from 'echarts';
	import dotPop from "@/components/dot-pop.vue"
	import messagePop from '../components/message-pop.vue'
	import mapButtonBar from "@/components/mapButtonBar.vue";
	import zLabel from "@/components/z-label.vue"
	import arcTemplate from "@/components/arcTemplate.vue"
	import axios from 'axios';
	
	var g_x = null;
	var g_y = null;

	var g_handler = null;
	var g_entity_house = null;
	var g_focus_entity = null; //选中的地块
	var g_focus_zu_index = -1;
	var g_fly_manager = null;

    /**手环参数设置 */
	var g_test_gj_data = {
		'sh001': [{x: 114.67138,y: 29.68597},{x: 114.67127,y: 29.68619},{x: 114.67039,y: 29.68575},{x: 114.66924,y: 29.6852},{x: 114.66913,y: 29.68546},{x: 114.6688,y: 29.68592},{x: 114.66904,y: 29.68603},{x: 114.66886,y: 29.68641},{x: 114.66884,y: 29.68683}],
		'sh002': [{x:114.66492,y:29.68203},{x:114.66497,y:29.68232},{x:114.66456,y:29.68235} ,{x:114.66426,y:29.6823},{x:114.66419,y:29.68288},{x:114.66411,y:29.68305},{x:114.66425,y:29.68337},{x:114.66426,y:29.6836}]
	}
	var g_handring_path_index = 0;
	var g_handring_maker_move = null;
	var g_handring_lines = [];
	var g_interval_gj = null;
	var g_handring_envelope = [];  //手环坐标范围
	var g_handring_path_envelope = [];  //手环轨迹坐标范围

	var g_handring_if_test = true;  //测试环境下，使用自带的假数据来展示手环数据和轨迹
	// var g_entity_zu_list = [];
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			lineBox,
			itemTitle,
			dotPop,
			messagePop,
			mapButtonBar,
			lineBorderBox,
			zLabel,
			arcTemplate
		},
		props: {
			basicData: String
		},
		data: function() {
			return {
            	isShow:true,//矿山安防
				
				tableCur:0,
				dotData: {},
				dotPopActive: -1,
				v_is_show_zu: true, //村两委，村小组选择
				v_zu_list: [], //村小组列表
				//村两委列表
				v_cun_committee: [],
				// 乡村动态数据
				xcdt_data: [],
				// 云上生活数据
				yssh_data: [],
				// 搜索状态
				searchState: false,
				// 搜索列表状态
				landWinPop: false,
				// 搜索内容
				searchText: '',
				// 人口数据
				population: [{
					title: '人口数',
					num: '--',
					unit: '人',
					icon: require('@/assets/img/govern/a1.png')
				}, {
					title: '户数',
					num: '--',
					unit: '户',
					icon: require('@/assets/img/govern/a2.png')
				}, {
					title: '外出务工',
					num: '--',
					unit: '人',
					icon: require('@/assets/img/govern/a3.png')
				}, {
					title: '男女比例',
					num: '--',
					unit: '%',
					icon: require('@/assets/img/govern/a4.png')
				}],
				zdrq_data: [{
					icon: require('@/assets/img/govern/e1-s.png'),
					icon_select: require('@/assets/img/govern/e1-h.png'),
					type: '强戒人员',
					arguments: 'treatment',
					num: '--',
					unit: '个',
					list: [],
					envelope: '',
					color:'#498DF3'
				}, {
					icon: require('@/assets/img/govern/e2-s.png'),
					icon_select: require('@/assets/img/govern/e2-h.png'),
					type: '精神病人',
					arguments: 'psycho',
					num: '--',
					unit: '个',
					list: [],
					envelope: '',
					color:'#7F5BE3'
				}, {
					icon: require('@/assets/img/govern/e3-s.png'),
					icon_select: require('@/assets/img/govern/e3-h.png'),
					type: '吸毒人员',
					arguments: 'drug',
					num: '--',
					unit: '个',
					list: [],
					envelope: '',
					color:'#E55F2E'
				}, {
					icon: require('@/assets/img/govern/e4-s.png'),
					icon_select: require('@/assets/img/govern/e4-h.png'),
					type: '涉赌人员',
					arguments: 'gambling',
					num: '--',
					unit: '个',
					list: [],
					envelope: '',
					color:'#F4E732'
				}, {
					icon: require('@/assets/img/govern/e5-s.png'),
					icon_select: require('@/assets/img/govern/e5-h.png'),
					type: '问题少年',
					arguments: 'problemchild',
					num: '--',
					unit: '个',
					list: [],
					envelope: '',
					color:'#F59931'
				}],
				emphasis_active: -1,
				cwgk_cur_active: 1,
				rkgl_cur_active: 1,
				v_if_show_video: false,
				v_if_show_radio: false,
				
				v_video_list: [],
				v_radio_list: [],
				//v_handring_list: [],//手环
				v_video_count: [0, 0, 0, 0, 0, 0],
				v_video_envelope: [],
				v_radio_envelope: [],
				//v_handring_envelope: [],  //手环

				player: Function,
				v_video_url: 'https://monitor.tsszxc.cn:7086/live/cameraid/1000003%240/substream/1.m3u8',//EasyPlayer
				v_video: {x: 0, y: 0},

				//v_handring: {x: 0, y: 0, id: '', mc: '', xb: '', csrq: '', sg: '', tz: '', lxfs: ''},
				v_if_show_handring: false,//手环是否定位显示
				v_handring_list: [],     //手环集合
				v_handring: {x: 0, y: 0, id: '', mc: ''},//手环详情
				v_handring_msg: {
					"data": {
						"battery": "",
						"bloodSugar": "",
						"dbp": "",
						"heartRate": "",
						"oxygen": "",
						"pedometer": "",
						"sdp": "",
						"temperature": "",
						"wgsLat": "",
						"wgsLng": ""
					},
					"info": {
						"bindTime": "",
						"bizId": "",
						"bpCorrectionHigh": 0,
						"bpCorrectionLow": 0,
						"bpThresholdHigh": 0,
						"bpThresholdLow": 0,
						"deviceMobileNo": "",
						"fallingAlarm": "",
						"height": 0,
						"hrmThresholdHigh": 0,
						"hrmThresholdLow": 0,
						"id": 0,
						"imei": "",
						"initTime": "",
						"lastOnlineTime": "",
						"model": "",
						"name": "",
						"ownerBirthday": "",
						"ownerBloodType": "",
						"ownerGender": "",
						"restricted": "",
						"sosContact": "",
						"status": "",
						"tenantCode": "",
						"weight": 0
					},
					"locationTrack": [
						{
							"imei": "",
							"time": "",
							"wgsLat": "",
							"wgsLon": ""
						}
					],
					"warnings": [
						{
							"address": "",
							"battery": 0,
							"content": "",
							"gcjLatitude": "",
							"gcjLongitude": "",
							"id": 0,
							"imei": "",
							"tenantCode": "",
							"warningType": "",
							"wgsLatitude": "",
							"wgsLongitude": ""
						}
					]
				},
				v_handring_path_show: false,//手环轨迹

				v_if_fly: false,
				v_fly_list: [],
				v_fly_video_map: {},

				v_if_show_pkh: false,
				v_pkh_info: {xm: '', zpyy: '', pfdc: '', pfry: '', sscmc: '', shape: [0,0]},

				v_hu_pane_show: false, //是否显示户面板
				v_hu_pane_index: 0, //0-家庭成员 1-地块信息
				v_hu_info: {
					xm: '',
					dz: '',
					lxdh: '',
					jzmj: '',
					rkList: [],
					dkList: []
				},
				transfromLeft: '',
				transfromRight: '',
				cz_list_active: -1,
				// 人口管理饼图数据
				peopleArr: [],
				// 村规民约数据
				cgmyData: {},
				// 人口弹窗数据
				rk_data: {},
				messageActive: 0,
				// 乡村动态弹窗配置
				xcdtOption: {
					width: 600,
					height: 400,
					type: 'left',
					name: '乡村动态'
				},
				villageData: {},
				clw_noImg: require('../assets/img/party/no_img.png'),
				focusPeopleState: false,
				focusPeopleType: '',
				// 关爱人群当前选中柱
				garq_show_name: '',
				g_xqid: localStorage.getItem('tenantCode'), //config.TENANT_CODE; //'420626107204';
				g_dsz_id: getDiShiZhouCodeByCunCode(localStorage.getItem('tenantCode')),
				colorList: ['#ff9387', '#ffc688', '#64e2ae', '#80cef3', '#b980f3'],
				dotColor: '',
				landSearchData: [],
				zdrq_data_sum:100,
				emphasis_echarts:[]
			}
		},
		beforeCreate() {
			g_map_type = 2;
		},
		created() {
			let that = this
            this.isShow = localStorage.getItem('tenantCode') == '421224107207'
			document.onkeydown = function(e) {
				var ev = document.all ? window.event : e;
				if (ev.keyCode == 13 && that.searchText != '') {
					that.searchLand()
				}
			}
           
		},
		mounted() {
			let pMountedTimer = window.setInterval(() => {
				if (window.parentMounted) {
					window.clearInterval(pMountedTimer)
					this.init();
				}
			}, 500)
		},
		computed: {
			...mapState(['menu_active'])
		},
		watch: {
			menu_active: function(newValue, oldValue) {
				this.dotPopActive = -1
				this.searchState = false
				this.searchText = ''
				if (newValue == 1) {
					if (newValue != oldValue) {
						this.showZuPolygon();
					}
				}
				if (newValue == 2) {
					this.initPieEcharts();
				}
				if (newValue == 3) {
					this.getSpecialPeople();
				}
				if (newValue != 1) {
					this.hideZuPolygon();
				}
				if (newValue != 2) {
					cleanMarkerListByType('rkcx');
					cleanMarkerListByType('fang');
					this.closeHuPane();
				}
				if (newValue != 3) {
					cleanMarkerListByType('garq');
					cleanMarkerListByType('focusPeople');
					this.messageActive = 0;
				}
				if (newValue != 4) {
					cleanMarkerListByType('video');
					cleanMarkerListByType('radio');
					this.dotPopActive = -1;
				}
			},
			searchText: function(newVal, oldVal) {
				this.landSearchData = []
				this.searchState = false
			}
		},
		methods: {
			change_rk_data:function(){
				alert(12)
			},
			init: function() {
				this.g_xqid = localStorage.getItem('tenantCode');
				this.requestCunXiaoZuList();
				this.requestVideoList();
				this.requestRadioList();
				if(g_handring_if_test){
					this.requestHandRingListTest();
				}else{
					this.requestHandRingList();  //手环
				}

				//this.requestFlyLine();
				this.initPieEcharts();
				this.getSpecialPeople();
				// 获取领导班子数据
				this.getStaff();
				// this.getFocusPeopleNum()
				//初始化重点人口，5类人群
				this.initZdrkList();
				g_handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

				this.$parent.show2DMap();
			},
			// 人口管理-搜索村民
			searchLand: function() {
				let that = this
				let json = {
					filterStr: this.searchText
				}
				getSearchPeople(json).then(res => {
					if (res.code != 200) {
						this.$emit('showMessage', res.msg)
						return
					}
					that.landSearchData = []
					res.rows.forEach(item => {
						if (item.householdId) {
							that.landSearchData.push(item)
						}
					});
					if (that.landSearchData.length < 1) {
						this.$emit('showMessage', '暂未收录该用户相关信息！')
					} else {
						this.searchState = true
					}
				})
			},
			closePane: function() {
				this.messageActive = 0
				cleanMarkerListByType('rkcx');
				cleanMarkerListByType('fang');
			},
			// 获取领导班子
			getStaff: function() {
				getStaff().then(res => {
					this.v_cun_committee = res.data
				})
			},
			getFocusPeopleNum: function() {
				getFocusPeople().then(res => {
					this.zdrq_data[0].num = res.data.treatment
					this.zdrq_data[1].num = res.data.psycho
					this.zdrq_data[2].num = res.data.drug
					this.zdrq_data[3].num = res.data.gambling
					this.zdrq_data[4].num = res.data.problemChild
				})
			},
			// 获取村务公开数据
			// getVillageNews: function () {
			//     let json = {
			//         pageSize: 4,
			//         pageNum: 1
			//     }
			//     getVillageNews(json).then(res => {
			// 		this.xcdt_data = res.rows
			//     })
			// },
			// 获取村规民约数据
			// getRulesList: function () {
			//     getRulesList().then(res => {
			// 		this.cgmyData = res.rows[0]
			//     })
			// },
			// 获取云上生活相关数据
			// getCloudSpace: function () {
			//     let json = {
			//         pageSize: 15,
			//         pageNum: 1
			//     }
			//     getCloudSpace(json).then(res => {
			// 		this.yssh_data = res.data.newInteractList
			//     })
			// },
			// 获取所有居民户
			// getHousehold: function () {
			//     getHousehold().then(res => {
			//         console.log(res, '获取所有居民户相关数据-------------')
			//     })
			// },
			// 获取特殊人群详情
			getPeopleInfo: function(type) {
				this.dotData = []
				this.dotPopActive = -1
				if (this.focusPeopleType != type) {
					this.focusPeopleType = type
					this.focusPeopleState = true
				} else {
					this.focusPeopleState = false
					this.focusPeopleType = ''
					return
				}
				let data = {
					type: type
				}

				getFocusPeopleList(data).then(res => {
					this.dotData = res.rows
					this.dotIcon = require('../assets/marker/m_person.png')
					this.dotPopActive = 3
				})
			},

			initZdrkList: function(){
				//查询5类人口信息
				for(var i=0;i<this.zdrq_data.length;i++){
					this.requestZdrkListByType(i, this.zdrq_data[i].type);
				}
			},
			requestZdrkListByType: function(index, type) {
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_zdrk"],
					"queryParameter": {
						"attributeFilter": "SSCID= '" + this.g_xqid + "' and rklx='"+type+"' ",
						"fields": ["smid", "xm"],
						"orderby": 'id'
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
					console.log('--5类人口回调--', type, result.totalCount);
					if (result.features.length >= 0) {
						//this.v_radio_list = [];
						this.zdrq_data[index].list = [];
						for (var i = 0; i < result.features.length; i++) {
							var _feature = result.features[i];
							var _item = {
								id: i,
								mc: _feature.fieldValues[1],
								type: 'zdrk',
								shape: [_feature.geometry.center.x, _feature.geometry.center.y]
							};
							//this.v_zdrk_list.push(_item);
							this.zdrq_data[index].list.push(_item);
						}
						this.zdrq_data[index].num = result.features.length;
						this.zdrq_data[index].envelope = getEnvelope(result.features);
						//this.v_radio_envelope = getEnvelope(result.features);
					}
				});
			},

			initPieEcharts: function() {
				let that = this
				// 获取人口管理相关数据
				getPeople().then(res => {
					// console.log(res, '----')
					echarts.init(document.getElementById('pie_echarts')).dispose();
					this.peopleArr = []
					// 人口年龄分布数据
					let typeList = ['20岁以下', '21-40岁', '41-60岁', '61-80岁', '81岁以上']
					for (let i = 0; i < 5; i++) {
						let data = {
							value: res.data.ageIntervalValue[i],
							name: typeList[i],
						}
						this.peopleArr.push(data)
					}
					let option = {
						tooltip: {
							show: true,
							position: function (point, params, dom, rect, size) { // 提示框位置
        						let x = 0;
        						let y = 0;
        						//固定在中间
        						// if (point[0] + size.contentSize[0] > size.viewSize[0]) {
        						//    x = point[0] + size.contentSize[0]
        						// } else {
        						   x = point[0]
        						// }
        						if (point[1] > size.contentSize[1]) {
        						   y = point[1] - size.contentSize[1]
        						} else if (point[1] + size.contentSize[1] < size.viewSize[1]) {
        						   y = point[1]
        						} else {
        						   y = "50%"
        						}
        						return [x, y];
      						},
							formatter: function(params) {
								let a = "<span style='margin: 0 10px;'>:</span>"
								let b =
									`<span style='font-weight:700;margin-right:10px;'>${params.value}</span>`
								return `${params.marker}${params.name}${a}${b}人`
							},
							trigger: 'item'
						},
						series: [{
							type: 'pie',
							radius: '90%',
							data: that.peopleArr,
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							},
							labelLine: {
								show: false,
							},
							label: {
								normal: {
									show: false,
									position: 'inner',
									color: '#fff',
									formatter: function(d) {
										that.emphasis_echarts.push(d.percent)
										return d.percent + '%'
									},
									textStyle: {
										color: '#fff',
										fontSize: 14 // 提示文字大小
									}
								}
							},
							itemStyle: {
								normal: {
									color: function(colors) {
										var colorList = ['#ff9387', '#ffc688', '#64e2ae',
											'#80cef3', '#b980f3'
										]
										return colorList[colors.dataIndex];
									}
								},
							}
						}],

					};
					let charts = echarts.init(document.getElementById('pie_echarts'));
					charts.setOption(option);
					window.addEventListener("resize", () => {
						that.emphasis_echarts = []
						charts.resize();
					});

					let data = JSON.parse(this.basicData)
					// 人口数
					this.population[0].num = data.population
					// 户数
					this.population[1].num = data.household
					// 外出务工
					this.population[2].num = data.outsideWorkPerson || 0 
					// 男女比例
					this.population[3].num = data.maleFemaleRatio
					
				})
			},
			getSpecialPeople() {
				getSpecialPeopleList().then(res => {
					// 关爱人群数据
					let xData = []
					let yData = []
					res.rows.forEach(item => {
						xData.push(item.careName)
						yData.push(item.careNumber)
					})
					this.geiCareData(xData, yData)
				})
			},
			//显示组
			showZuPolygon: function() {
				var colorArr = ['#f18990', '#c6a73d', '#73c3fe', '#32c599', '#b980f3'];
				for (var i = 0; i < this.v_zu_list.length; i++) {
					var _item = this.v_zu_list[i];
					var config = {
						polygonFillColor: colorArr[i % 5],
						alpha: 0.6,
						classificationType: 'S3M_TILE',
						cx: _item.cx,
						cy: _item.cy
					};
					var _label = _item.mc;
					if (_item.zrr) {
						_label += '\n组长：' + _item.zrr;
					}
					if (_item.hs) {
						_label += '\n' + _item.hs + '户';
					}
					if (_item.rs) {
						_label += ' ' + _item.rs + '人';
					}
					addPolygonFunction('zu', i, _label, _item.shape, '', config);
				}
			},

			toggleZu() {
				this.v_is_show_zu = !this.v_is_show_zu;
				if (this.v_is_show_zu) {
					this.showZuPolygon();
				} else {
					this.hideZuPolygon();
				}
			},

			//隐藏组
			hideZuPolygon: function() {
				cleanMarkerListByType('zu');
				viewer.entities.removeById('focus-line');
			},

			onMoveMapToZuPolygon: function(item, index) {
				var _entity = getMakerById('zu-' + item.id);
				if (_entity) {
					this.cz_list_active = index
					viewer.flyTo(_entity, {
						offset: {
							heading: 0.0,
							pitch: -1.5,
							range: 100.0
						}
					});
					this.focusEntityBorder(index);
				}

			},

			onCheckHouse: function() {
				viewer.entities.removeById('identify-area');
				cleanMarkerListByType('hdk');
				this.v_hu_pane_show = false;
				viewer._element.style.cursor = "crosshair";
				g_handler.setInputAction(this.handlerAction, Cesium.ScreenSpaceEventType.LEFT_CLICK);
			},

			handlerAction: function(movement) {

				var position = viewer.scene.pickPosition(movement.position);
				var cartographic = Cesium.Cartographic.fromCartesian(position);
				var longitude = Cesium.Math.toDegrees(cartographic.longitude);
				var latitude = Cesium.Math.toDegrees(cartographic.latitude);
				var height = cartographic.height;
				console.log('点选房屋--', longitude, latitude);
				g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
				viewer._element.style.cursor = 'pointer';
				this.requestHouse(longitude, latitude);
			},

			requestHouse: function(x, y) {
				var _sqlParameter = {
					"getFeatureMode": "SPATIAL",
					"datasetNames": ["p" + this.g_dsz_id + ':t02_jmd'], //////****临时用的图层 */
					'spatialQueryMode': "INTERSECT",
					"queryParameter": {
						fields: ["smid", "sfzh", "sscid"]
					},
					'hasGeometry': true,
					'geometry': {
						'parts': [1],
						'type': "POINT",
						'points': [{
							'id': "SuperMap.Geometry_12",
							'bounds': null,
							'SRID': null,
							'x': x,
							'y': y,
							'tag': null,
							'type': "Point",
							'geometryType': "Point"
						}]
					}
				}

				http.requestIServerWithPage(_sqlParameter, 0, 9, (result) => {
					console.log('--居民地空间查询回调--', result.totalCount);
					if (result.featureCount > 0) {
						var _feature = result.features[0];
						this.addClapFeature(_feature);
						this.v_hu_pane_show = true;
						var _hid = _feature.fieldValues[1];
						if (_hid) {
							this.requestHuInfoByHid(_hid);
							this.requestRkList(_hid);
							this.requestDkList(_hid);
						} else {
							this.inputEmptyHuInfo();
						}

					}
				});
			},

			addClapFeature: function(feature) {
				var lonLatArr = getLonLatArray(feature.geometry.points);
				//console.log(lonLatArr);
				viewer.entities.add({
					id: 'identify-area',
					name: '单体化标识面',
					polygon: {
						hierarchy: Cesium.Cartesian3.fromDegreesArray(lonLatArr),
						material: new Cesium.Color(1.0, 0.0, 0.0, 0.6),
						classificationType: Cesium.ClassificationType.S3M_TILE
					}
				});
			},

			requestHuInfoByHid: function(hid) {
				console.log('--户查询', hid);
				var _str_filter = "SSCID= '" + this.g_xqid + "' ";
				if (hid) {
					_str_filter += " and sfzh = '" + hid + "' ";
				}
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_hu"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["id", "bh", "hzxm", "sfzh", "zu", "hao", "dz", "lxdh", "hjzmj", "fwjs", "fwcs",
							"sfkz", "zp"
						]
					},
					"maxFeatures": 10
				}
				http.requestIServer(_sqlParameter, (result) => {
					console.log('--户查询回调--', result.totalCount);
					if (result.features.length > 0) {
						this.v_hu_pane_show = true;
						var _feature = result.features[0];
						this.v_hu_info.xm = _feature.fieldValues[2];
						this.v_hu_info.dz = _feature.fieldValues[6];
						this.v_hu_info.lxdh = _feature.fieldValues[7];
						this.v_hu_info.jzmj = getTwoBitDotNumber(_feature.fieldValues[8]);
					}

				});
			},
			closeHuPane: function() {
				this.v_hu_pane_show = false;
				this.rk_data = {}
				this.messageActive = 0;
				viewer.entities.removeById('identify-area');
				cleanMarkerListByType('hdk');
				cleanMarkerListByType('rkcx');
				cleanMarkerListByType('fang');
			},
			inputEmptyHuInfo: function() {
				this.v_hu_info = {
					xm: '-',
					dz: '-',
					lxdh: '-',
					jzmj: '-',
					rkList: [],
					dkList: []
				};
			},

			requestRkList: function(hid) {
				//户成员
				var _str_filter = "SSCID= '" + this.g_xqid + "' ";
				if (hid) {
					_str_filter += " and hid = '" + hid + "' ";
				}
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_rk"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["id", "xm", "xb", "mz", "sfzh", "csrq", "lxfs", "yhzgx"],
						"orderby": 'csrq'
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
					console.log('--户成员查询回调--', result.totalCount);
					if (result.features.length > 0) {
						this.v_hu_info.rkList = [];
						for (var i = 0; i < result.features.length; i++) {
							var _feature = result.features[i];
							this.v_hu_info.rkList.push({
								xm: _feature.fieldValues[1],
								xb: _feature.fieldValues[2],
								csrq: _feature.fieldValues[5],
								yhzgx: _feature.fieldValues[7]
							});
						}
					}
				});
			},

			requestDkList: function(hid) {
				//地块查询
				var _str_filter = "SSCID= '" + this.g_xqid + "' ";
				if (hid) {
					_str_filter += " and sfzh = '" + hid + "' ";
				}
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_dk"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["ID", "QSRMC", "SFZH", "DKMC", "DKLX", "SCMJ", "M1", "M2", "M3", "M4", "M5",
							"M6", "M7", "M8", "M9", "M10", "M11", "M12", "X", "Y"
						],
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 50, (result) => {
					console.log('--地块询回调--', result.totalCount);
					if (result.features.length > 0) {
						this.v_hu_info.dkList = [];
						for (var i = 0; i < result.features.length; i++) {
							var _feature = result.features[i];
							var _item = {
								mc: _feature.fieldValues[3],
								lx: _feature.fieldValues[4],
								mj: _feature.fieldValues[5],
								shape: getLonLatArray(_feature.geometry.points)
							};
							this.v_hu_info.dkList.push(_item);
							var _config = {
								polygonFillColor: '#00ff00',
								alpha: 0.6,
								classificationType: 'S3M_TILE'
							};
							addPolygonFunction('hdk', i, _item.mc, _item.shape, '', _config);
						}
					}
				});
			},

			onFlyToDkPolygon(index) {
				console.log(index);
				var _entity = getMakerById('hdk-' + index);
				// console.log('-----', index, _entity);
				if (_entity) {
					viewer.flyTo(_entity, {
						offset: {
							heading: 0.0,
							pitch: -1.5,
							range: 100.0
						}
					});
					this.focusEntity(_entity);
				}
			},

			focusEntity(entity) {
				if (g_focus_entity) {
					console.log('--****g_focus_entity----', g_focus_entity);
					// g_focus_entity.polygon.outline = false;
					g_focus_entity.polygon.material = g_focus_entity.polygon.material2;
				}
				if (entity) {
					console.log('--****entity----', entity)
					entity.polygon.material2 = entity.polygon.material;
					// entity.polygon.outline = true;
					entity.polygon.material = Cesium.Color.RED;
					// entity.polygon.outlineWidth = 2;
					g_focus_entity = entity;
				}
			},

			focusEntityBorder(index) {
				console.log('--****g_focus_zu_index----', g_focus_zu_index);
				if (g_focus_zu_index > -1) {
					viewer.entities.removeById('focus-line');
				}
				if (index > -1) {
					var _config = {
						width: 5,
						lineColor: '#ffc748'
					};
					addLine('focus', 'line', '', this.v_zu_list[index].shape, null, _config);
					g_focus_zu_index = index;
				}
			},

			requestCunXiaoZuList: function() {
				var _str_filter = "SSCID= '" + this.g_xqid + "' ";
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_zu"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["id", "mc", "hs", "rs", "zrr", "lxfs", "cx", "cy"],
						"orderby": 'id'
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
					console.log('--村小组回调--', result.totalCount);
					if (result.features.length > 0) {
						this.v_zu_list = [];
						for (var i = 0; i < result.features.length; i++) {
							var _feature = result.features[i];
							var _lonLatArr = getLonLatArray(_feature.geometry.points);
							this.v_zu_list.push({
								id: i,
								mc: _feature.fieldValues[1],
								hs: _feature.fieldValues[2],
								rs: _feature.fieldValues[3],
								zrr: _feature.fieldValues[4],
								lxfs: _feature.fieldValues[5],
								cx: _feature.fieldValues[6], //中心点x
								cy: _feature.fieldValues[7], //中心点y
								shape: _lonLatArr
							});
						}
						if (this.menu_active == '1') {
							this.showZuPolygon();
						}
					}
				});
			},
			// 获取关爱人群数据
			geiCareData: function(xData, yData) {
				let option = {
					xAxis: {
						type: 'category',
						data: xData,
						axisLine: {
							lineStyle: {
								color: "#fff"
							}
						},
						axisLabel: {
							interval: 0,
							color: "#fff",
							fontSize: '.12rem',
							formatter: function(value) {
								var ret = ""; //拼接加\n返回的类目项  
								var maxLength = 2; //每项显示文字个数  
								var valLength = value.length; //X轴类目项的文字个数  
								var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数  
								if (rowN > 1) //如果类目项的文字大于5,  
								{
									for (var i = 0; i < rowN; i++) {
										var temp = ""; //每次截取的字符串  
										var start = i * maxLength; //开始截取的位置  
										var end = start + maxLength; //结束截取的位置  
										//这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧  
										temp = value.substring(start, end) + "\n";
										ret += temp; //凭借最终的字符串  
									}
									return ret;
								} else {
									return value;
								}
							}
						}
					},
					grid: {
						top: "10%",
						left: "10%",
						right: "0",
						bottom: "24%"
					},
					tooltip: {
						trigger: 'axis',
						borderColor: "#092B46",
						backgroundColor: "#092B46",
						textStyle: {
							color: "#ffffff",
							fontSize: 14,
							align: 'left'
						},	
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							if(params[0].name.indexOf('贫困')!=-1)
								return params[0].name + ': ' + params[0].value + '户';
							return params[0].name + ': ' + params[0].value + '人';
						}
					},
					yAxis: {
						type: 'value',
						splitLine: { //网格线
							show: true,
							lineStyle: {
								// 使用深浅的间隔色
								color: "rgba(225, 225, 225, 0.15)"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#fff"
							}
						},
						axisLabel: {
							color: "#fff",
							fontSize: ".12rem",
							fontWeight: '300'
						}
					},
					series: [{
						data: yData,
						barWidth: 50,
						name: 'hill',
						type: 'pictorialBar',
						barCategoryGap: '40%',
						symbol: 'triangle',
						color: {
							type: 'linear',
							x: 0, //右
							y: 0, //下
							x2: 0, //左
							y2: 1, //上
							colorStops: [{
									offset: 0,
									color: '#21fffa' // 0% 处的颜色
								},
								{
									offset: 1,
									color: '#003cb2' // 100% 处的颜色
								}
							]
						}
					}]
				}
				echarts.init(document.getElementById('industryCharts')).dispose();
				let charts = echarts.init(document.getElementById('industryCharts'));
				setTimeout(res => {
					charts.setOption(option, true);
				}, 500)
				charts.on('click', params => {
					console.log(params.name, '柱状图点击事件');
					if(params.name.indexOf('贫困')!=-1){
						this.requestPkhList();
					}else{
						this.requestRkListByRklx(params.name);
					}
				})
			/*	let that = this
				charts.getZr().on('click', function (params) {
                    let pointInPixel = [params.offsetX, params.offsetY];
                    if (charts.containPixel('grid', pointInPixel)) {
                        let pointInGrid = charts.convertFromPixel({
                          seriesIndex: 0
                        }, pointInPixel);
                        let xIndex = pointInGrid[0]; 			// 索引
                        let handleIndex = Number(xIndex);
						let name = xData[handleIndex];
						if(name.indexOf('贫困')!=-1){
							that.requestPkhList();
						}else{
							that.requestRkListByRklx(name);
						}
						that.closeHuPane();
                    }
                })*/

				window.addEventListener("resize", () => {
					charts.resize();
				});
			},

			requestPkhList(){
				//
				console.log('查询贫困户');
				this.dotData = [];
				this.dotColor = '#15CDD8';
				this.dotPopActive = -1;
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t05_pkh"],
					"queryParameter": {
						"attributeFilter": "sscid='"+this.g_xqid+"'",
						"fields": ["hid", "hzxm", "zpyy", "bfdc", "bfry", "sscmc"]
					},
					"maxFeatures": 1000
				}
				http.requestIServerWithPage(_sqlParameter, 0, 1000, (result) => {
					console.log('--查询贫困户回调--', result.totalCount);
					for (var item of result.features) {
						item.name = item.fieldValues[1];
						item.zpyy = item.fieldValues[2];
						item.bfdc = item.fieldValues[3];
						item.bfry = item.fieldValues[4];
						item.sscmc = item.fieldValues[5];
						item.shape = [item.geometry.center.x, item.geometry.center.y];
						item.type = 'pkh';
						this.dotData.push(item);
					}
					if (result.features.length > 0) {
						var _envelope = getEnvelope(result.features);
						viewer.camera.flyTo({
							destination: Cesium.Rectangle.fromDegrees(
								_envelope[0],
								_envelope[1],
								_envelope[2],
								_envelope[3]
							) //west, south, east, north)
						});
					}

					this.dotIcon = require('../assets/marker/m_person.png');
					this.dotPopActive = 2
				});
			},
			onClosePkh(){
				this.v_if_show_pkh = false;
			},

			requestRkListByRklx(rklx) {
				console.log(rklx, '-----点击柱状图事件--------')
				this.dotData = []
				this.dotColor = '#15CDD8'
				this.dotPopActive = -1
				if (rklx == this.garq_show_name) {
					this.garq_show_name = ''

					return
				}
				this.garq_show_name = rklx

				var _str_filter = "SSCID= '" + this.g_xqid + "' ";
				if (rklx) {
					_str_filter += " and rklx like '" +encodeURI('%') + rklx +encodeURI('%')+ "' ";
				}
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_rk"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["id", "xm", "xb", "mz", "sfzh", "csrq", "lxfs", "yhzgx"],
						"orderby": 'csrq'
					},
					"maxFeatures": 2000
				}
				http.requestIServerWithPage(_sqlParameter, 0, 2000, (result) => {
					console.log('--按人口类型查询回调--', result.totalCount);

					let repeatArr = []
					for (var item of result.features) {
						// var _config = { image: require('../assets/marker/member.png'), width: 20, height: 31, z: this.$parent.getZ() };
						// var _shape = [item.geometry.center.x, item.geometry.center.y];

						item.name = item.fieldValues[1]
						let arr = [item.geometry.center.x, item.geometry.center.y]
						let string = item.geometry.center.x + '/' + item.geometry.center.y
						item.shape = arr
						if (repeatArr.indexOf(string) == -1) {
							repeatArr.push(string)
							this.dotData.push(item)
						} else {
							this.dotData[repeatArr.indexOf(string)].name = this.dotData[repeatArr.indexOf(string)]
								.name + '、' + item.fieldValues[1]
						}

						// addPoint('garq', item.fieldValues[0], item.fieldValues[1], _shape, '', _config);
					}
					if (result.features.length > 0) {
						var _envelope = getEnvelope(result.features);
						viewer.camera.flyTo({
							destination: Cesium.Rectangle.fromDegrees(
								_envelope[0],
								_envelope[1],
								_envelope[2],
								_envelope[3]
							) //west, south, east, north)
						});
					}

					this.dotIcon = require('../assets/marker/m_person.png');
					this.dotPopActive = 2
				});
			},

			requestZdrqByType: function(index, item){
				var _envelope = this.zdrq_data[index].envelope;
				if (_envelope.length > 0) {
					viewer.camera.flyTo({
						destination: Cesium.Rectangle.fromDegrees(
							_envelope[0],
							_envelope[1],
							_envelope[2],
							_envelope[3]
						) //west, south, east, north)
					});
				}
				this.dotData = this.zdrq_data[index].list;
				this.dotIcon = require('../assets/marker/m_person.png');
				this.dotPopActive = 3
			},
			//请求视频
			requestVideoList: function() {
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t05_abss_sxt"],
					"queryParameter": {
						"attributeFilter": "SSCID= '" + this.g_xqid + "' ",
						"fields": ["smid", "mc", "url", "json", "scwz"],
						"orderby": 'id'
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
					console.log('--视频回调--', result.totalCount);
					if (result.features.length > 0) {
						this.v_video_list = [];
						for (var i = 0; i < result.features.length; i++) {
							var _feature = result.features[i];
							var _item = {
								id: i,
								mc: _feature.fieldValues[1],
								url: _feature.fieldValues[2],
								json: _feature.fieldValues[3],
								scwz: _feature.fieldValues[4],
								type: 'video',
								shape: [_feature.geometry.center.x, _feature.geometry.center.y]
							};
							this.v_video_list.push(_item);
							// if(_item.scwz == '')//
						}
						this.v_video_envelope = getEnvelope(result.features);
					}
				});
			},

			//请求广播
			requestRadioList: function() {
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t04_gb"],
					"queryParameter": {
						"attributeFilter": "SSCID= '" + this.g_xqid + "' ",
						"fields": ["smid", "mc"],
						"orderby": 'id'
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
					console.log('--广播回调--', result.totalCount);
					if (result.features.length > 0) {
						this.v_radio_list = [];
						for (var i = 0; i < result.features.length; i++) {
							var _feature = result.features[i];
							var _item = {
								id: i,
								mc: '',
								type: 'radio',
								shape: [_feature.geometry.center.x, _feature.geometry.center.y]
							};
							this.v_radio_list.push(_item);
						}
						this.v_radio_envelope = getEnvelope(result.features);
					}
				});
			},

			//页面初始化时，请求所有手环简要信息  测试用
			requestHandRingListTest: function () {
				//initDeviceWatchList
				this.v_handring_list = [];
				//请求后台 ......
				var _str_filter = "SSCID = '" + this.g_xqid + "' and imei is not null ";
				_str_filter += " and (rklx like '%五保%' or rklx like '%贫困户%' or rklx like '%退役军人%' or rklx like '%留守儿童%' or rklx like '%残疾人%' or rklx like '%精神障碍%')";
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_rk_tsga"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["id", "xm", 'imei'],
						"orderby": 'id'
					},
					"maxFeatures": 2000
				}
				http.requestIServerWithPage(_sqlParameter, 0, 2000, (result) => {
					console.log('--回调i定制人口--', result.totalCount);
					var _xy_list = [];
					for (var _item of result.features) {
						var item = {};
						item.id = _item.fieldValues[2];
						item.mc = _item.fieldValues[1];
						item.shape = [_item.geometry.center.x, _item.geometry.center.y];
						item.type = 'handring';
						this.v_handring_list.push(item);
						_xy_list.push({x: _item.geometry.center.x, y: _item.geometry.center.y});
						
					}
					g_handring_envelope = getEnvelopeByPoints(_xy_list);
				});
			},
			 //请求手环详细信息   测试用
			requestHandRingInfoTest: function (obj) {
				var imei = obj.id;
				if(!imei){
					console.log('imei为空');
					return;
				}
				console.log('请求手环详细信息---测试', obj);
				//watchDetail({imei: imei}).then(res => {
				axios({
					method:'get',
					url: 'data/'+imei+'.json',
				}).then((response) => {
					var result = response.data;
					if (result.code == 200) {
						this.v_if_show_handring = true;
						this.v_handring_msg = result.data;//填充手环详情
						this.v_handring_msg.info.name = obj.mc;
						//计算轨迹范围
						var _xy_list = [];
						for (var item of this.v_handring_msg["locationTrack"]) {
							_xy_list.push({ x: item.wgsLon, y: item.wgsLat });
						}
						g_handring_path_envelope = getEnvelopeByPoints(_xy_list);
					}
				});
			},

			//页面初始化时，请求所有手环简要信息
			requestHandRingList: function(){
				this.v_handring_list = [];
				watchList().then(res => {
					 //console.log(res);
					if(res.code == 200){
						var _xy_list = [];
						for(var item of res.data.watchList){
							//title: '姓名1', imei: '861265060554190', wgsLat: '29.68575', wgsLon: '114.67039'
							//console.log(item.title);
							var _x = typeof item.wgsLon == 'string'?parseFloat(item.wgsLon):item.wgsLon;//判断字符串类型的坐标
							if(!_x){_x = 114.65989;}
							var _y = typeof item.wgsLat == 'string'?parseFloat(item.wgsLat):item.wgsLat;
							if(!_y){_y = 29.66118;}
							this.v_handring_list.push({id: item.imei, mc: item.title, shape: [_x, _y], type: 'handring'});
							_xy_list.push({x: _x, y: _y});
						}
						//计算合适的范围
						g_handring_envelope = getEnvelopeByPoints(_xy_list);
						//g_handring_envelope = [114.65776,29.68149, 114.67879, 29.69380];
					}
				});
			},

			//请求手环详细信息
			requestHandRingInfo: function(imei){
				watchDetail({imei: imei}).then(res => {
					console.log('info----',res);
					if(res.code == 200){
						this.v_handring_msg = res.data;//填充手环详情
						this.v_if_show_handring = true;//显示手环详情面板
						let info = res.info
						// this.v_handring_msg.info = res.info
						let that = this
						
						//计算轨迹范围
						var _xy_list = [];
						for(var item of this.v_handring_msg["locationTrack"]){
							_xy_list.push({x: item.wgsLon, y: item.wgsLat});
						}
						g_handring_path_envelope = getEnvelopeByPoints(_xy_list);
					}
					
				});
			},

			//是否显示手环的轨迹
			toggleHandRingPath: function(){
				if(this.v_handring_msg["locationTrack"].length == 0){
					this.$message.error('该手环历史坐标信息为空，不能绘制轨迹！');
					return;
				}
				if(this.v_handring_path_show){
					this.cleanLastPath();
				}else{
					this.showHandRingPath();
				}
				this.v_handring_path_show = !this.v_handring_path_show;
			},
			
			//显示手环轨迹
			showHandRingPath: function(){
				this.cleanLastPath();
				this.onCloseHandring();//关掉手环面板，这个遮挡了轨迹
				if (g_handring_path_envelope.length > 0) {
					viewer.camera.flyTo({
						destination: Cesium.Rectangle.fromDegrees(
							g_handring_path_envelope[0],
							g_handring_path_envelope[1],
							g_handring_path_envelope[2],
							g_handring_path_envelope[3]
						) //west, south, east, north)
					});
					this.walkPath();
					g_interval_gj = window.setInterval(()=>{this.walkPath()}, 2000);
				}
			},
			//动态画轨迹
			walkPath(){
				var _point = this.v_handring_msg["locationTrack"][g_handring_path_index];
				console.log('轨迹', g_handring_path_index, _point.wgsLon, _point.wgsLat);
				//绘制第一个点
				if(g_handring_path_index == 0){
					//创建动点
					var _config = {};
					_config.image = require('@/assets/marker/handring-move.png');
					_config.width = 20;
					_config.height = 27;
					_config.offsetX = -10;
					g_handring_maker_move = addPoint('handringmove', 'man', '', [_point.wgsLon, _point.wgsLat], '', _config);
				}else{
					//移动点
					g_handring_maker_move.position = Cesium.Cartesian3.fromDegrees(_point.wgsLon, _point.wgsLat, 5);
					//画线段
					var _point_last = this.v_handring_msg["locationTrack"][g_handring_path_index-1];
					var _config = { width: 5 };
                	addLine('handringpath', g_handring_path_index, '', [_point.wgsLon, _point.wgsLat, _point_last.wgsLon, _point_last.wgsLat], null, _config);
				}

				//准备下一个点位
				g_handring_path_index++;
				//关掉轨迹运动状态
				if(g_handring_path_index == this.v_handring_msg["locationTrack"].length){
					if(g_interval_gj){
						window.clearInterval(g_interval_gj);
					}
				}
			},
			cleanLastPath(){
				g_handring_path_index = 0;// 指针归位
				cleanMarkerListByType('handringpath');//清除轨迹
				this.v_if_show_handring = false;//关闭详情面板
				viewer.entities.removeById('handringmove-man');//清除轨迹动点
				if(g_interval_gj){//关闭定时器
					window.clearInterval(g_interval_gj);
					g_interval_gj = null;
				}
			}, 

			//切换视频，广播，手环
			onCheckType2: function(type) {
				this.closeHuPane();
				this.cleanLastPath();
				type == this.dotPopActive ? this.dotPopActive = -1 : this.dotPopActive = type
				//console.log('this.v_video_list===',this.v_video_list)
				switch (type) {
					case 0:
						this.dotIcon = require('../assets/marker/m_video.png');
						this.dotData = this.v_video_list
						this.dotColor = '#009CFF'
						if (this.v_video_envelope.length > 0) {
							viewer.camera.flyTo({
								destination: Cesium.Rectangle.fromDegrees(
									this.v_video_envelope[0],
									this.v_video_envelope[1],
									this.v_video_envelope[2],
									this.v_video_envelope[3]
								) //west, south, east, north)
							});
						}
						this.v_if_show_video = false;
						return;
					case 1:
						this.dotIcon = require('../assets/marker/m_radio.png');
						this.dotData = this.v_radio_list
						this.dotColor = '#FF6C00'
						if (this.v_radio_envelope.length > 0) {
							viewer.camera.flyTo({
								destination: Cesium.Rectangle.fromDegrees(
									this.v_radio_envelope[0],
									this.v_radio_envelope[1],
									this.v_radio_envelope[2],
									this.v_radio_envelope[3]
								) //west, south, east, north)
							});
						}
						return;
					case 2:
						this.dotIcon = require('../assets/marker/m_handring_0.png');
						this.dotData = this.v_handring_list
						this.dotColor = '#009CFF'
						if (g_handring_envelope.length > 0) {
							viewer.camera.flyTo({
								destination: Cesium.Rectangle.fromDegrees(
									g_handring_envelope[0],
									g_handring_envelope[1],
									g_handring_envelope[2],
									g_handring_envelope[3]
								) //west, south, east, north)
							});
						}
						return;
				}
			},

			openPop(item){
				//console.log(item.url);
				if(item.url){
					this.v_video.x = item.shape[0];
					this.v_video.y = item.shape[1];
					this.v_video_url = item.url;
					this.showVideoWindow();
					return;
				}

				if(item.type == 'handring'){
					//this.v_if_show_handring = true;
					//请求手环详细信息
					console.log('openPop imei', item.id);
					if(g_handring_if_test){  //测试专用 
						this.requestHandRingInfoTest(item);
					}else{
						this.requestHandRingInfo(item.id);
					}
					this.v_handring.x = item.shape[0];
					this.v_handring.y = item.shape[1];
					this.v_handring.id = item.id;
					this.v_handring.mc = item.mc;
					// this.v_handring.xb = item.xb;
					// this.v_handring.csrq = item.csrq;
					// this.v_handring.sg = item.sg;
					// this.v_handring.tz = item.tz;
					// this.v_handring.lxdh = item.lxdh;
				}else if(item.type == 'pkh'){
					if(this.v_if_show_pkh){
						this.v_if_show_pkh = false;
					}else{
						this.v_pkh_info = item;
						this.v_if_show_pkh = true;
					}
				}
				
			},

			onCloseHandring() {
				this.v_if_show_handring = false;
			},

			//请求飞图路线
			requestFlyLine: function() {
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": [config.MAIN_PG_DATABASE + ":t_cun_fly"],
					"queryParameter": {
						"attributeFilter": "sscid= '" + this.g_xqid + "' ",
						"fields": ["fid", "mc", "path"],
						"orderby": 'fid'
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
					console.log('--飞图路线请求--', result.totalCount);
					if (result.features.length > 0) {
						this.v_fly_list = [];
						for (var _feature of result.features) {
							this.v_fly_list.push({
								fid: _feature.fieldValues[0],
								mc: _feature.fieldValues[1],
								path: _feature.fieldValues[2]
							});
						}
					}
				});
			},
			//单击，关闭或开始飞行
			onClickFlyManage: function() {
				this.v_if_fly = !this.v_if_fly;
				if (this.v_if_fly) { //飞
					if (this.v_fly_list.length == 0) {
						return;
					}
					var _fid = this.v_fly_list[0].fid;
					console.log('fid', _fid);
					if (this.v_fly_video_map[_fid]) { //如果已查询过，则直接飞
						this.beginFlyWithVideo();
					} else { //没有查询过，则请求
						this.requestFlyVideoList(_fid);
					}
				} else { //停飞
					if (g_fly_manager) {
						g_fly_manager.stop();
					}
					this.onCloseVideo();
				}
			},

			//飞图视频
			requestFlyVideoList: function(fid) {
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": [config.MAIN_PG_DATABASE + ":t_cun_fly_video"],
					"queryParameter": {
						"attributeFilter": "fid= '" + fid + "' ",
						"fields": ["id", "mc", "url"],
						"orderby": 'id'
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
					console.log('--飞图视频请求--', result.totalCount);
					if (result.features.length > 0) {
						this.v_fly_video_map[fid] = [];
						for (var _feature of result.features) {
							this.v_fly_video_map[fid].push({
								id: _feature.fieldValues[0],
								mc: _feature.fieldValues[1],
								url: _feature.fieldValues[2]
							});
						}
						this.beginFlyWithVideo();
					}
				});
			},

			beginFlyWithVideo: function() {
				var rc = new Cesium.RouteCollection();
				var g_url_fly = config.URL_IUPLOAD + this.v_fly_list[0].path; //'upload/421024106203/fly/yzc01.fpf';
				rc.fromFile(g_url_fly);

				//初始化飞行管理
				g_fly_manager = new Cesium.FlyManager({
					scene: viewer.scene,
					routes: rc
				});

				g_fly_manager.readyPromise.then(() => {
					g_fly_manager && g_fly_manager.play();
					this.v_if_show_video = true;
				});

				var _list = this.v_fly_video_map[this.v_fly_list[0].fid];
				var _video_index = 0;
				if (_video_index > _list.length - 1) { //序号超过
					return;
				}
				var _url = _list[_video_index].url;
				this.baiduMp4(_url);
				g_fly_manager.stopArrived.addEventListener((routeStop) => {
					if (routeStop.index > _list.length - 1) { //序号超过
						return;
					}
					_url = _list[routeStop.index].url;
					this.baiduMp4(_url);
					console.log(routeStop.index, _url, routeStop);
					//_video_index++;
				});
			},

            requestVideoData: function(){
				console.log('---矿山安防摄像头---');
				if(this.v_video.mc){//如果已经缓存了信息，就不用再请求图层信息
					this.showVideoWindow();
					return;
				}
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t05_abss_sxt"],
					"queryParameter": {
						"attributeFilter": "SSCID= '" + this.g_xqid + "' and mc='矿山安防摄像头'",
						"fields": ["smid", "mc", "url", "json", "scwz"],
						"orderby": 'id'
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
					console.log('--矿山安防摄像头 视频回调--', result.totalCount);
					if (result.features.length > 0) {
						for (var i = 0; i < result.features.length; i++) {
							var _feature = result.features[i];
							this.v_video.x = _feature.geometry.center.x;
							this.v_video.y = _feature.geometry.center.y;
							this.v_video.mc = _feature.fieldValues[1];  //缓存信息
							this.v_video_url = _feature.fieldValues[2];
							this.showVideoWindow();
							break;
						}
					}
				});
			},
			showVideoWindow: function(){
				if(this.v_video_url){
					this.v_if_show_video = true;
					this.dotPopActive = 0;
					this.$nextTick(()=>{this.baiduMp4(this.v_video_url);});
					//缩放到合适范围
					var hcv = {
						destination: Cesium.Cartesian3.fromDegrees(this.v_video.x, this.v_video.y, 2500)
					}
					viewer.camera.flyTo(hcv);
				}
			},
			//播放视频链接
			baiduMp4(mp4Url) {
				console.log('--video---', mp4Url);
				//this.v_video_url = mp4Url;
				document.getElementById('player').setAttribute('video-url', mp4Url);
			/*	this.player = cyberplayer('id_video_play').setup({
					width: "100%", // 宽度，也可以支持百分比(不过父元素宽度要有)
					height: "100%", // 高度，也可以支持百分比
					title: "基本功能", // 标题
					file: mp4Url,
					image: "", // 预览图
					autostart: true, // 是否自动播放
					stretching: "uniform", // 拉伸设置
					repeat: false, // 是否重复播放
					volume: 100, // 音量
					controls: true, // controlbar是否显示
					starttime: 0, // 视频开始播放时间点(单位s)，如果不设置，则可以从上次播放时间点续播
					ak: "39f82ac87fc3462ea4dcc78734450f57" // 公有云平台注册即可获得accessKey
				});*/
			},
			onCloseVideo() {
				if(document.getElementById('player'))
					document.getElementById('player').setAttribute('video-url', "");
				this.v_if_show_video = false;
				// if (this.player) {
				// 	this.player.remove();
				// }
			},
			// 人口查询
			flyToPeople: function(item) {
				this.searchText = item.name
				this.searchState = false
				this.landSearchData = []
				cleanMarkerListByType('rkcx');
				cleanMarkerListByType('fang');
				// this.$parent.hideMapButtonBarHuInfoPane();
				this.$refs['mapButtonBar'].v_hu_pane_show = false;
				let data = {
					householdId: item.householdId
				}
				this.messageActive = 3
				getHouseholdDetail(data).then(res => {
					if (res.code == 500) {
						this.$notify({
							title: '警告',
							message: res.msg,
							type: 'warning'
						});
						this.closeHuPane()
					}
					this.rk_data = res.data
					if (this.rk_data.address.indexOf('湖北省') != -1) {
						this.rk_data.address = this.rk_data.address.substring(6)
					}
					addPoint('rkcx', this.rk_data.id, '', this.rk_data.geometry.pointArr, '', {
						z: 10,
						image: require('@/assets/marker/m_person.png')
					});
					//显示房屋面
					for(var i=0;i<res.data.houseGeometryList.length;i++){
						var _config = {
							polygonFillColor: '#ffff00',
							alpha: 0.6,
							classificationType: 'S3M_TILE'
						};
						var _item = res.data.houseGeometryList[i];
						addPolygonFunction('fang', i, '', _item.pointArr, '', _config);
					}
					var z = 500;
					var x = this.rk_data.geometry.pointArr[0]
					var y = this.rk_data.geometry.pointArr[1]
					var hcv = {
						//将经度、纬度、高度的坐标转换为笛卡尔坐标
						destination: Cesium.Cartesian3.fromDegrees(x, y, z),
						orientation: {
							heading: 0.0,
							pitch: -1.5,
							range: 5000.0
						}
					}
					viewer.camera.flyTo(hcv);
				})
			}
		},
		beforeDestroy() {
	      this.onCloseVideo();
	    },
		destroyed() {
			//销毁房屋选中状态
			viewer.entities.removeById('identify-area');
			//this.v_hu_pane_show = false;
			///this.messageActive = 0;   //隐藏户信息框
			//cleanMarkerListByType('hdk');
			this.closeHuPane();
			viewer._element.style.cursor = 'pointer';
			cleanMarkerListByType('zu');
			viewer.entities.removeById('focus-line');
			cleanMarkerListByType('video');
			cleanMarkerListByType('radio');
			cleanMarkerListByType('rkcx');
			cleanMarkerListByType('fang');
			cleanMarkerListByType('focusPeople');
			cleanMarkerListByType('garq');
			this.dotPopActive = -1;
			g_handler = g_handler && g_handler.destroy();
			
			viewer.entities.removeById('focus-line');
			this.cleanLastPath();
			this.v_if_show_pkh = true;

			this.$off();
	      	this.$destroy();
		}
	}
</script>

<style lang="scss"  scoped>
.no-data{
	text-align: center;
	margin-top: 0.3rem;
}
.btn-box{
  position: absolute;
  bottom: 0.52rem;
  right: 4.2rem;
  z-index: 9;
  .btn-img{
    display: inline-block;
    width: 0.8rem;
    height:  0.8rem;
    background: url(../../static/img/01.png) no-repeat;
    background-size: 100% 100%;
    &:hover{
      background: url(../../static/img/011.png) no-repeat;
       background-size: 100% 100%;  
    }
  }
}
	.row {
		display: flex;
    	flex-wrap: wrap;
    	justify-content: space-between;
		margin-top: 0.2rem;
		div {
			list-style-type: none;
			color: #fff;
			line-height: .18rem;
			&>img{
				margin-right: 0.05rem;
			}
			span:first-child {
				color: rgba(255, 255, 255, 0.6);
			}

			line-height: 0.18rem;
		}
		.flex_c{
			    display: flex;
    			flex-wrap: wrap;
    			justify-content: flex-start;
    			width: 50%;
				margin-bottom: 0.1rem;
				&>div>p:first-child{
					color: rgba(255,255,255,0.6);
				}
		}
	}
	.row-2 {
		display: flex;
		width: 100%;
		justify-content: space-between;
		span {
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 1.24rem;
			height: 0.24rem;
			// background: #042B66;
			border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
			font-size: 0.14rem;
			color: #fff;
			margin-top: 0.16rem;
			cursor: pointer;
			border: 1px solid rgba(0,255,255,0.3);
		}
		.cur_pop_list {
			border: 0.01rem solid #00FFFF;
		}
		.cur_pop_list_track{
			background: url(../assets/img/farming/btn-bg.png) repeat-x;
    	background-size: 100% 100%;
    	width: 0.95rem;
    	height: 0.24rem;
    	border: 0;
    	font-size: 0.12rem;
			}
	}
	.info{
		color: #03F6F7;
		font-size: 0.14rem;
		margin-top: 0.1rem;
		img{
			margin-right: 0.05rem;
		}
	}
	.row-3 {
		text-align: center;
		margin-top: 0.05rem;
		background-color: rgba(4, 43, 102, 0.75);
		height: 0.24rem;
		display: flex;
		align-items: center;
		justify-content: space-around;
		font-size: 0.14rem;
		color: rgba(255, 255, 255, 0.6);
		span:first-child{
			width: 40%;
		}
		span:last-child{
			width: 60%;
		}
	}
	.row-4{
		display: flex;
    	flex-wrap: wrap;
    	justify-content: space-between;
		margin-top: 0.1rem;
		.list{
			margin-top: 0.05rem;
		}
	}
	.row-5{
		text-align: center;
		font-size: 0.14rem;
		span:first-child{
			width: 40%;
		}
		span:last-child{
			width: 60%;
		}
		.list{
			width: 50%;
			color: rgba(0,0,0,0.3);
			span{
				color: #FFFFFF;
			}
			
		}
	}
	.row-5 {
		margin: 0.1rem 0;
		text-align: center;
		font-size: 0.14rem;
		color: #fff;
		display: flex;
		justify-content: space-around;
		.f-yollow{
				color: #EDE133;
		}
	}
	.page_temp {
		color: rgb(35, 73, 13);
	}

	.cls-model-title {
		text-align: center;
		border-bottom: .01rem dashed #fff;
		margin-bottom: .2rem;
		height: .3rem;
		line-height: .3rem;
		font-size: .15rem;
	}

	.cls-pane-clw {
		min-height: 150px;

	}

	.cls-click-house {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 12px;
		color: red;
	}

	.cls-hu-title {
		text-align: center;
		font-weight: bold;
		color: white;
		background: #9e9e9e;
		height: 30px;
		line-height: 30px;
		padding-right: 10px;
		border-radius: 3px;
	}

	#id-hu-info {
		position: absolute;
		height: 350px;
		width: 400px;
		z-index: 999;
		bottom: 0px;
		right: 400px;
		background-color: #ccc;
		border-radius: 10px;
	}

	.cls-hu-button {
		height: 20px;
		cursor: pointer;
		color: #369;

	}

	.cls-hu-pane {
		height: 180px;
		width: 100%;
		border: 1px solid salmon;
		overflow: auto;
	}

	.cls-hu-pane span {
		margin: 0 2px;
	}

	.cls-close-hu {
		float: right;
		color: red;
		cursor: pointer;
	}

	.cls-video-list {
		display: flex;
		justify-content: space-between;
		/* height: .9rem; */
		flex-wrap: wrap;
	}

	.cls-video-type {

		color: #ccc;
		width: 40%;
		height: .9rem;
		border: 1px solid #369;
		overflow: hidden;
		/* float: left; */
	}

	#id_video {
		position: absolute;
		z-index: 1000;
		bottom: 10px;
		right: 500px;
		width: 200px;
		height: 150px;
		border: 1px solid rgb(188, 194, 188);
		background-color: black;
	}

	.cls-tip-close {
		cursor: pointer;
		float: right;
		margin: 3px;
	}

	.v-html>>>img {
		width: 100%;
	}
</style>
<style lang="scss" scoped>
	.message-pop {
		font-size: 0.14rem !important;
		overflow: hidden;
		width: 100%;
		height: 100%;

		.pop-content {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;

			.title {
				margin: 0.1rem 0;
				display: inline-block;
				width: 100%;
				text-align: center;
				padding: 0.1rem 0.3rem;
			}

			.v-html {
				flex: 1;
				display: block;
				width: 100%;
				overflow: auto;
			}
		}
	}

	//村组管理 
	.villager {
		margin-top: .08rem;
		padding: 0 0.08rem 0.2rem;
		display: flex;
		flex-direction: column;

		.cls-button-list {
			width: 100%;
			height: .42rem;

			div {
				width: 50%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-top: 4%;
				box-sizing: border-box;
			}

			.cls-type-checked {
				background-image: linear-gradient(0deg,
						#1fd7fcc0 0%,
						rgba(31, 215, 252, 0.446) 45%,
						transparent 100%);
			}
		}

		.title-line {
			width: 100%;
			height: .04rem;
			display: flex;
			padding: 0 .15rem;
			box-sizing: border-box;

			div {
				width: 50%;
				height: 100%;
				box-sizing: border-box;
				border-top: 1px solid #ddd;
				border-bottom: 1px solid #ddd;
			}

			.cls-type-checked {
				border: none;
				background-color: #1fd7fcfd;
			}

		}

		.committee {
			width: 100%;
			display: flex;
			height: 2rem;
			flex-direction: column;
			overflow: hidden;
			overflow: auto;
			box-sizing: border-box;

			.committee-item {
				width: 2.7rem;
				display: flex;
				flex-direction: row;
				display: flex;
				align-items: center;
				margin-bottom: .1rem;

				.img {
					border-radius: 50%;
					box-sizing: border-box;

					img {
						width: 100%;
						object-fit: cover;
					}
				}

				ul {
					list-style-type: none;

					li {
						font-size: .12rem;
						font-weight: 500;
						color: #EBEFF5;
						span {
							font-size: .12rem;
						}
					}

					margin-left: .14rem;
					height: .6rem;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
				}
			}
		}

		.cz {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			width: 100%;

			.showBtn {
				width: 1.2rem;
				height: .3rem;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #333;
				font-size: .15rem;
				background-image: linear-gradient(0deg,
						#1fd7fc 0%,
						#79b7f7 100%),
					linear-gradient(#1fd7fc,
						#1fd7fc);
				background-blend-mode: normal,
					normal;
				border-radius: .05rem;
				margin: .2rem .15rem 0 0;
				cursor: pointer;
			}

			.cz_list {
				width: 100%;
				display: flex;
				align-items: center;
				// height: .24rem;
				box-sizing: border-box;
				span {
					display: flex;
					// justify-content: center;
					color: rgba(255,255,255,0.6);
					align-items: center;
					font-size: .13rem;
				}
				span:first-child {
					width: 40%;
				}
				span:nth-child(2){
					width: 10%;
				}
				span:nth-child(3){
					width: 20%;
				}
				span:last-child {
					width: 30%;
				}
			}

			.cz_list_box {
				width: 100%;
				overflow: auto;
				height: 1.5rem;
				justify-content: flex-start;
				box-sizing: border-box;

				.cz_list {
					cursor: pointer;
					color: rgba(255,255,255,0.9);
				}

				.cz_list:hover {
					color: #ffc748;
				}

			}
		}
	}

	.affairs {
		height: 4.4rem;
		padding: .1rem 0;

		.title,
		.xcdt,
		.cgmy,
		.yssh {
			padding: 0 .15rem !important;
			box-sizing: border-box;
		}
	}

	.v-html>>>.ql-align-justify {
		color: red !important;
	}

	.v-html>>>.ql-align-justify img {
		width: 100%;
	}

	.cls-pop-pane {
		font-size: 16px;
		height: 320px;
	}

	.affairs,
	.population {
		display: flex;
		flex-direction: column;

		.title {
			width: 100%;
			display: flex;
			justify-content: space-around;
			border-bottom: 1px dashed rgba(255, 255, 255, 0.376);

			span {
				padding: 0 .08rem .08rem;
				transform: translateY(1px);
				cursor: pointer;
				border-radius: .02rem;
			}

			.cwgk_cur_active {
				border-bottom: .04rem solid #1fd7fc;
			}
		}

		.xcdt {
			width: 100%;
			display: flex;
			flex-direction: column;

			.xcdt_item {
				display: flex;
				margin-top: .16rem;
				cursor: pointer;
				font-size: .14rem;

				.img {
					width: 1.14rem;
					height: .8rem;
					border-radius: .1rem;
					border: 1px solid #ddd;
					overflow: hidden;

					img {
						width: 100%;
						object-fit: cover;
					}
				}

				.content {
					margin-left: .14rem;
					font-size: .14rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}

				.content>span {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					font-size: .14rem;
				}

				.content>div {
					display: flex;
					justify-content: space-between;

					span:first-child {
						width: .9rem;
						height: .2rem;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						border-radius: .04rem;
						font-size: .12rem;
					}

					.news {
						background-color: #ff8b2e;
						border: solid 1px #ffa55d;
					}

					.notice {
						background-color: #19bbff;
						border: solid 1px #19bbff;
					}
				}
			}

			.xcdt_item:hover {
				.content>span {
					color: #fff000;
				}

				.img {
					border-color: #19bbff;
				}
			}
		}

		.cgmy {
			width: 100%;
			height: 100%;
			overflow: auto;

			span {
				display: inline-block;
				width: 100%;
				height: .4rem;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: .15rem;
				margin-top: .1rem;
			}

			p {
				font-size: .14rem;
				line-height: .26rem;
			}
		}

		.yssh {
			padding: .14rem .1rem;

			div {
				display: flex;
				align-items: center;
			}

			.icon {
				display: inline-block;
				width: .06rem;
				height: .06rem;
				background-color: #1fd7fc;
				transform: rotateZ(45deg);
				margin-right: .1rem;
			}

			span {
				font-size: .14rem;
			}

			.tit {
				display: -webkit-box;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;

				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
		}

		.yssh>div {
			height: .36rem;
			cursor: pointer;
			display: flex;
			justify-content: space-between;

			div {
				width: 60%;
			}
		}

		.yssh>div:hover {
			color: #19bbff;
		}
	}

	.search {
		width: auto;
		height: .37rem;
		border-radius: .17rem;
		border: solid 1px #79b7f7;
		padding-left: .16rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: #fff;
		box-sizing: border-box;
		z-index: 900;
		margin: 0.08rem;
		
		background: rgba(4,43,102,0.6);
		box-shadow: inset 0px 0px 0.08rem 0px #0856CC;
		border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
		opacity: 1;
		border: 0.01rem solid #0856CC;

		input {
			flex: 1;
			width: 40%;
			padding-left: .04rem;
			margin-left: .04rem;
			border: none;
			outline: none;
			background-color: transparent;
			color: #fff;
			height: .2rem;
			line-height: .2rem;
		}

		span {
			font-size: .14rem;
			width: 50%;
		}

		i {
			font-size: .17rem;
			cursor: pointer;
		}

		.search-btn {
			background: #09C8E5;
			border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
			
			margin-right: .01rem;
			border-radius: .15rem;
			width: .8rem;
			height: .3rem;
			box-sizing: border-box;
			font-size: .12rem;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			padding: 0;
			margin-right: 0.04rem;
			cursor: pointer;
		}

		.searchListBox {
			width: 100%;
			position: absolute;
			z-index: 999;
			left: 0;
			top: .52rem;
			display: flex;
			justify-content: center;

			.searchList {
				width: 90%;
				height: auto;
				border-radius: 0px 0px .08rem .08rem;
				border: solid .01rem #1fd7fc;
				background-color: #00000092;
				box-shadow: 0px 0px .06rem 0px #1fd7fc inset;
				padding: .1rem;
				box-sizing: border-box;

				li {
					list-style-type: none;

					span:first-child {
						color: #fff000;
						margin-right: .2rem;
						cursor: pointer;
						display: inline-block;
						width: .5rem;
						text-align: justify;
						text-align-last: justify;

					}
				}
			}
		}
	}

	.populationList {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: .14rem 0;

		div {
			width: 50%;
			display: flex;
			padding: .08rem 0;
			box-sizing: border-box;
			font-size: .14rem;

			span {
				display: inline-block;
				width: .68rem;
				text-align-last: justify;
				text-align: justify;
				text-justify: distribute-all-lines;
			}

			p {
				font-size: .16rem;
				color: #fff000;
			}
		}
	}

	.pie_box {
		width: 100%;

		.pie_title {
			display: flex;
			width: 100%;
			justify-content: center;
			font-size: .16rem;
		}

		.pie_box_content {
			margin-top: .08rem;
			display: flex;
			justify-content: space-between;

			.pie_echarts_box::-webkit-scrollbar {
				display: none;
				/* Chrome Safari */
			}

			.pie_echarts_box {
				width: 1.6rem;
				height: 1.6rem;
				display: flex;
				justify-content: center;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 0.02rem solid rgba(0,255,255,0.3);
				border-radius: 50%;
				position: relative;
				.center{
					width: 0.4rem;
					height: 0.4rem;
					background-color: #142D50;
					position: absolute;
					z-index: 99;
					border-radius: 50%;
				}
				.pie_echarts {
					width: 1.58rem;
					height: 1.58rem;
				}

				.pie_echarts::-webkit-scrollbar {
					display: none;
					/* Chrome Safari */
				}

				img {
					width: .5rem;
					height: .5rem;
					border-radius: 50%;
					position: absolute;
					top: 9;
				}
			}
		}
	}

	.pie_legend {
		list-style-type: none;
		flex: 1;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		font-size: .14rem;
		line-height: .28rem;

		li {
			display: flex;
			width: 76%;
			margin-left: 0.32rem;
			align-items: center;
			justify-content: space-between;

			i {
				width: .16rem;
				height: .09rem;
				background-color: #333;
				margin-right: .1rem;
			}

		}

		li:nth-child(1) i {
			background-color: #ff9387;
			// box-shadow: 0 0 0 4px #ff93875e;
		}

		li:nth-child(2) i {
			background-color: #ffc688;
			// box-shadow: 0 0 0 4px #ffc5885c;
		}

		li:nth-child(3) i {
			background-color: #64e2ae;
			// box-shadow: 0 0 0 4px #64e2ad5d;
		}

		li:nth-child(4) i {
			background-color: #80cef3;
			// box-shadow: 0 0 0 4px #80cff358;
		}

		li:nth-child(5) i {
			background-color: #b980f3;
			// box-shadow: 0 0 0 4px #ba80f357;
		}
	}

	.population {
		padding: 0 0.08rem;
		.title {
			span {
				width: 50%;
				display: flex;
				justify-content: center;
			}
		}

		.histogram {
			span {
				font-size: .12rem;
				display: inline-block;
				width: 100%;
				text-align: left;
			}
		}

		.emphasis {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.emphasis_item {
				width: 48%;
				height: .40rem;
				padding: .04rem .02rem .04rem .06rem;
				box-sizing: border-box;
				display: flex;
				margin-top: .08rem;
				background-color: rgba(255, 255, 255, 0.393);
				align-items: center;
				border-radius: .05rem;
				cursor: pointer;

				img {
					height: 100%;
					margin-right: .06rem;
				}

				.size14 {
					font-size: .14rem;
				}

				.size16 {
					font-size: .16rem;
					margin: 0 .04rem;
				}

				.emphasis_active {
					span {
						color: #fff000;
					}
				}
			}
		}
	}

	.xcaf_box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		div {
			cursor: pointer;

			div {
				display: flex;
				justify-content: center;
			}
		}
	}

	.cls-button-list {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		padding: 0 .15rem;
		box-sizing: border-box;
	}

	.cls-button-list>div {
		width: 40%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: .14rem;
		cursor: pointer;

		img {
			width: 100%;
		}

		span {
			font-size: .14rem;
		}

		.size18 {
			font-size: .18rem;
			margin: 0 .04rem;
			font-weight: 700;
		}
	}

	// .cls-button-list>div:first-child{
	//     cursor: pointer;
	// }
	.cz_list_active {
		color: #ffc748;
		background-color: #ffc74820;
	}

	#id_pane_content {
		font-size: 0.14rem;
		list-style-type: none;
		padding: 0.2rem 0.1rem;
		line-height: 0.32rem;
	}

	.villager-item-title {
		margin-left: .2rem;
		font-size: .18rem;
		font-stretch: normal;
		line-height: .3rem;
		letter-spacing: 0px;
		color: #53b9ff;
		display: flex;
		align-items: center;
		font-style: italic;

		.span {
			display: flex;
			border-radius: 50%;
			width: 0;
			height: 0;
			box-shadow: 0 0 .1rem .04rem #fff;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: .1rem;

			span {
				position: absolute;
				display: flex;
				transform: translateX(.02rem);
				width: .1rem;
				height: .1rem;
				background-color: #fff;
				clip-path: polygon(0 0, 50% 50%, 0 100%);
			}
		}
	}

	.img-item {
		display: flex;
		align-items: center;
		width: 100%;
		background-color: rgba(225, 225, 225, 0.3);
		height: .68rem;
		border-radius: .04rem;
		margin-top: .15rem;
		box-sizing: border-box;
		overflow: hidden;
		padding-left: .24rem;

		img {
			margin-right: .16rem;
			width: .38rem;
			height: .38rem;
			border-radius: 50%;
		}

		div {
			font-size: .14rem;

			span {
				font-size: .16rem;
				margin: 0 .16rem;
				color: #fff000;
			}
		}
	}

	.img-box {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.img-item {
			width: 48%;
			cursor: pointer;

			div {
				display: flex;
				flex-direction: column;

				div {
					display: flex;
					flex-direction: row;
					align-items: center;
					line-height: .3rem;

					span {
						margin-left: 0;
						font-size: .18rem;
					}
				}
			}
		}
	}

	.content_img_item {
		width: 48%;
		height: 1.45rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: .2rem;
		cursor: pointer;

		div {
			width: 100%;
			height: 1.2rem;
			background-color: #ffffff;
			box-shadow: 0px 0px 10px 1px rgba(228, 255, 254, 0.3);
			border-radius: .04rem;
			border: solid 1px #fff;
		}

		div:hover {
			border: solid 1px #ffc748;
		}

		span {
			font-size: .14rem;
			color: #fff;
			line-height: .3rem;
		}
	}

	.module-box {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-top: .1rem;

		.module-item {
			width: 1.26rem;
			height: 1.26rem;
			margin-top: -0.2rem;
			background-image: url(../assets/img/server/a2.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			cursor: pointer;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.name {
				margin: .06rem 0;
			}

			.titles {
				font-size: .14rem;
				color: #ffc748;

				span {
					font-size: .24rem;
				}
			}
		}

		.module-item:hover {
			background-image: url(../assets/img/server/a1.png);

			.titles {
				color: #fff;

				span {
					color: #22f2ff;
				}
			}
		}
	}
	
	.info_title{
		display: flex;
		align-items: center;
		.info_dot{
			width: 0.12rem;
			height: 0.12rem;
			margin: 0.1rem 0;
			border: 0.01rem solid #069999;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			span{
				display: block;
				width: 0.06rem;
				height: 0.06rem;
				box-shadow: 0 0 0.03rem 0.01rem #18F5F5;
				background-color: #18F5F5;
			}
		}
		font-size: 0.14rem;
		color: #00FFFF ;
	}
	.dataList-item{
		width: 49%;
		height: 0.48rem;
		background: rgba(3,32,77,0.16);
		border: 0.01rem solid;
		margin-top: 0.1rem;
		border-image: linear-gradient(90deg, rgba(24, 142, 245, 1), rgba(24, 142, 245, 0)) 1 1;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		img{
			margin: 0 0.08rem;
			width: 0.4rem;
			height: 0.4rem;
			box-sizing: border-box;
		}
		.item-info{
			display: flex;
			flex-direction: column;
			font-size: 0.14rem;
			width: 100%;
			.type{
				background: linear-gradient(90deg, rgba(25,152,255,0.2) 0%, rgba(24,142,245,0) 100%);
				font-size: 0.14rem;
				font-family: Source Han Sans CN-Medium, Source Han Sans CN;
				font-weight: 500;
				color: #C4DEF5;
				line-height: 0.18rem;
				padding-left: 0.08rem;
			}
			div{
				display: flex;
				span:first-child{
					width: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 0.2rem;
					font-weight: bold;
					color: #19F5F5;
					line-height: 0.2rem;
					text-shadow: 0px 0px 0.08rem #3385FF;
				}
				span:last-child{
					display: flex;
					justify-content: flex-start;
					align-items: flex-end;
					font-size: 0.1rem;
					width: 50%;
					transform: scale(0.8);
					font-family: Source Han Sans CN-Medium, Source Han Sans CN;
					font-weight: 500;
					color: rgba(255,255,255,0.6);
					line-height: 0.12rem;
				}
			}
		}
	}
	.dataList-box{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 0.08rem;
	}
	.focusGroups{
		display: flex;
		justify-content: space-evenly;
		.item{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 0.72rem;
			height: 0.72rem;
			cursor: pointer;
			
			.round{
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				
				width: 0.54rem;
				height: 0.54rem;
				border-radius: 50%;
				backdrop-filter: blur(0.02rem);
				border: 0.02Crem solid rgba(225, 225, 225, 0.2);
			}
		}
	}
	.anfang{
		cursor: pointer;
		&:hover{
			background-color: #09fafa20;
		}
	}
</style>
