import axios from "../axios/index"

// 乡村治理云上生活
export const getCloudSpace = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/govern/cloudSpace',data)
}
// 查询所有居民户
export const getHousehold = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/govern/household',data)
}
// 人口管理
export const getPeople = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/govern/people',data)
}
// 乡村治理村规民约
export const getRulesList = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/govern/rulesList',data)
}
// 乡村安防
export const getSafe = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/govern/safe',data)
}
// 人口查询
export const getSearchPeople = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/govern/searchPeople',data)
}
// 获取领导班子
export const getStaff = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/govern/staff',data)
}
// 村组规范
export const getVillageArea = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/govern/villageArea',data)
}
// 乡村治理村务公开
export const getVillageNews = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/govern/villageNews',data)
}
// 获取村务公详情接口
export const getvillageInfo = (data) => {
	data.tenantId = localStorage.getItem('tenantId')
	return axios.get('/onemap/portal/govern/villageId',data)
}
// 获取居民户详情
export const getHouseholdDetail = (data) => {
	data.tenantId = localStorage.getItem('tenantId')
	return axios.get('/onemap/portal/govern/householdDetail',data)
}
// 获取重点人群数量
export const getFocusPeople = () => {
	let data = {
        tenantId:localStorage.getItem('tenantId')
    }
	return axios.get('/onemap/portal/govern/focusPeople',data)
}
// 获取重点人群列表
export const getFocusPeopleList = (data) => {
	data.tenantId = localStorage.getItem('tenantId')
	return axios.get('/onemap/portal/govern/focusPeopleList',data)
}
// 获取关爱人群列表
export const getSpecialPeopleList = () => {
	let tenantId = localStorage.getItem('tenantId')
	return axios.get('/onemap/portal/govern/care?tenantId='+tenantId+'&isShow=1')
}
//查询手环列表
export const watchList = () => {
	let data = {
        tenantId:localStorage.getItem('tenantId')
    }
	return axios.get('/onemap/portal/govern/watchList',data)
}
// 查询手环详情
export const watchDetail = (data) => {
	data.tenantId = localStorage.getItem('tenantId')
	return axios.get('/onemap/portal/govern/watchDetail',data)
}