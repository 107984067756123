<template>
	<div :id="'arc_echarts_'+index" class="arc_echarts" style="width: 0.72rem;height: 0.72rem;">

	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		props:{
			sum:{
				type:Number,
				default:100
			},
			singular:{
				type:Number,
				default:6
			},
			index:Number,
			color:{
				type:String,
				default:'#498DF3'
			}
		},
		mounted() {
			let that = this
			let option = {
				series: [{
					type: 'pie',
					radius: ['90%', '80%'],
					label: false,
					color: [that.color, 'rgba(225,225,225,0.2)'],
					data: [{
							value: that.singular,
							name: '单数'
						},
						{
							value: that.sum,
							name: '总数'
						}
					]
				}]
			}
			let charts = echarts.init(document.getElementById('arc_echarts_'+that.index));
			charts.setOption(option);
			window.addEventListener("resize", () => {
				charts.resize();
			});
		},
		destroyed() {
			// charts.dispose();
		}
	}
</script>

<style scoped>
	.arc_echarts{
		transform: rotate(-10deg);
		-webkit-transform: rotate(-10deg);
	}
</style>
