<template>
	<div style="display: flex;justify-content: space-between;align-items: center;">
		<div class="info_title">
			<div class="info_dot"><span></span></div>
			<span style="font-size: 0.14rem;color: #00FFFF;margin-left: 0.1rem;">{{name}}</span>
		</div>
		<slot></slot>
	</div>
</template>

<script>
	export default{
		props:{
			name:String
		}
	}
</script>

<style lang="scss">
	.info_title{
		display: flex;
		align-items: center;
		.info_dot{
			width: 0.12rem;
			height: 0.12rem;
			margin: 0.1rem 0;
			border: 0.01rem solid #069999;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			span{
				display: block;
				width: 0.06rem;
				height: 0.06rem;
				box-shadow: 0 0 0.03rem 0.01rem #18F5F5;
				background-color: #18F5F5;
			}
		}
		font-size: 0.14rem;
		color: #00FFFF ;
	}
</style>